// Root
export const SET_FROM = 'setFrom'
export const SET_BUSY = 'setBusy'

// Account
export const SET_OWNER_ACCOUNT = 'set_account'
export const SET_PHONES = 'set_phones'
export const SET_ADDRESSES = 'set_addresses'
export const SET_EMAILS = 'set_emails'
export const SET_GENERAL = 'set_general'
export const SET_ALL_PEOPLE = 'set_people'
export const SET_CURRENT_CONTACT = 'set_current_contact'
export const SET_MISSING_CONTACT_INFORMATION = 'set_missing_contact_information'
export const SET_ACCOUNT_DETAILS = 'set_account_details'
export const SET_USERNAME = 'set_username'
export const SET_DOES_USERNAME_EXIST = 'set_does_username_exist'
export const SET_USERNAME_TOKEN = 'set_username_token'
export const SET_IS_NOT_ACCOUNT_OWNER = 'set_is_not_account_owner'
export const SET_ACTIVE_SERVICES_COUNT = 'set_active_services_count'
export const SET_COMPANY_REQUIRING_STAGELINE_REDIRECT = 'set_company_requiring_stageline_redirect'
export const SET_COMPANY_REGISTRATIONS = 'set_company_registrations'
export const SET_STAGELINE_REDIRECTED = 'set_stageline_redirected'
export const SET_REDIRECT_LOADED = 'set_redirect_loaded'
export const SET_ACTIVE_ACCOUNT_SERVICES = 'set_active_account_services'
export const SET_ACTUAL_ACCOUNT_ID='set_actual_account_id'
export const SET_REDIRECT = 'set_redirect'
export const SET_ADMIN_IMPERSONATION = 'set_admin_impersonation'

// App
export const SET_ROUTE_LOADING = 'set_route_loading'
export const SET_CONNECTION_INFO = 'set_connection_info'

// Bandwidth webRTC
export const SET_WEB_RTC_TOKEN = 'set_web_rtc_token'
export const SET_PARTICIPANT_ID = 'set_participant_id'
export const SET_CALL_ON_HOLD_PARTICIPANT_ID = 'set_call_on_hold_participant_id'

// Calls
export const SET_CALL_ID = 'set_call_id'
export const SET_INCOMING_CALL_ID = 'set_incoming_call_id'
export const SET_IS_CALL_ACCEPTED = 'set_is_call_accepted'
export const SET_CALL_ON_HOLD = 'add_call_on_hold'
export const SET_CALL_RTC_STREAM = 'set_call_rtc_stream'

// Cancellation Reasons
export const SET_ALL_OPTIONS = 'set_all_options'
export const SET_OTHER_OPTIONS = 'set_other_options'

// Chat Rooms
export const SET_CHAT_ROOM = 'set_chat_room'

// Checkout
export const LOAD_PRODUCTS_START = 'load_products_start'
export const LOAD_PRODUCTS = 'load_products'
export const LOAD_PRODUCTS_FINISHED = 'load_products_finished'
export const LOAD_PRODUCTS_STATUS = 'load_products_status'
export const SET_HIRE_US_CONTEXT = 'set_hire_us_context'
export const CART_ITEMS_LOADED = 'cart_items_loaded'
export const SET_PROCESSING_CHECKOUT = 'set_processing_checkout'
export const SET_CHECKOUT_INVOICE_ID = 'set_checkout_invoice_id'
export const UPDATE_CART_ITEM_DATA = 'update_cart_item_data'
export const TOGGLE_OPTIONAL_ITEM = 'toggle_optional_item'
export const TOGGLE_HIRE_US_OPTIONAL_ITEM = 'toggle_hire_us_optional_item'
export const RESET_STATE_AFTER_CHECKOUT = 'checkout_reset_state'
export const SET_CHECKOUT_DISABLED = 'set_checkout_disabled'
export const SET_CURRENT_PRODUCT = 'set_current_product'
export const RESET_CURRENT_BUNDLE_ITEMS = 'reset_current_bundle_items'
export const SET_CURRENT_BUNDLE_ITEMS = 'set_current_bundle_items'

// Domains
export const RESET_DOMAINS = 'reset_domains'
export const SET_DOMAIN = 'set_domain'
export const SET_DOMAIN_RECORDS = 'set_domain_records'
export const SET_DOMAIN_LOCKED = 'set_domain_locked'
export const SET_DOMAINS = 'set_domains'
export const SET_HOSTING_TRANSFER_INFO = 'set_hosting_transfer_info'
export const SET_STANDALONE_HOSTING_INFO = 'set_standalone_hosting_info'
export const SET_DOMAIN_TRANSFER_REQUESTS = 'set_domain_transfer_requests'
export const UPDATE_DOMAIN_TRANSFER_REQUEST = 'update_domain_transfer_request'
export const SET_SELECTED_DOMAIN = 'set_selected_domain'
export const SET_DOMAIN_WHOIS = 'set_domain_whois'
export const SET_DOMAIN_SSL = 'set_domain_ssl'
export const SET_SELECTED_DOMAIN_RECORD = 'set_selected_domain_record'
export const ADD_DOMAIN_RECORD = 'add_domain_record'
export const UPDATE_DOMAIN_RECORD = 'update_domain_record'
export const DELETE_DOMAIN_RECORD = 'delete_domain_record'
export const SET_DOMAIN_NAMESERVERS = 'set_domain_nameservers'
export const SET_DOMAIN_INTERNET_NAMESERVERS = 'set_domain_internet_nameservers'
export const SET_WEB_HOSTING = 'set_web_hosting'
export const SET_DOMAINS_FOR_ACCOUNT = 'set_domains_for_account'
export const SET_TLDS = 'set_tlds'
export const SET_DOMAIN_AVAILABILITY = 'set_domain_availability'
export const SET_DOMAIN_AUTH_CODE = 'set_domain_auth_code'

// Domain Emails
export const SET_DOMAIN_EMAILS = 'set_domain_emails'
export const SET_DOMAIN_EMAIL = 'set_domain_email'
export const ADD_DOMAIN_EMAIL = 'add_domain_email'
export const UPDATE_DOMAIN_EMAIL = 'update_domain_email'
export const DELETE_DOMAIN_EMAIL = 'delete_domain_email'
export const SET_SELECTED_EMAIL = 'set_selected_email'
export const RESET_DOMAIN_EMAIL = 'reset_domain_email'

// Domain Suggestions
export const SET_DOMAIN_SUGGESTIONS = 'set_domain_suggestions'

// Company
export const LOAD_COMPANIES_START = 'load_companies_start'
export const LOAD_COMPANIES_FINISHED = 'load_companies_finished'
export const LOAD_COMPANIES_FAILED = 'load_companies_failed'
export const LOAD_SERVICES_FAILED = 'load_services_failed'
export const SET_COMPANY_RESULTS_PER_PAGE = 'set_company_results_per_page'
export const SET_CURRENT_COMPANY = 'set_current_company'
export const SET_COMPANY_ACTIVE_SERVICES = 'set_company_active_services'
export const SET_COMPANY_SUSPENDED_SERVICES = 'set_company_suspended_services'
export const SET_COMPANY_ACTIVE_ORDER_ITEMS = 'set_company_active_order_items'
export const SET_COMPANY_ACTIVE_RA_SERVICES = 'set_company_active_ra_services'
export const SET_COMPANY_ACTIVE_COMPLIANCE_SERVICES = 'set_company_active_compliance_services'
export const SET_COMPANY_ACTIVE_SUBSCRIPTIONS = 'set_company_active_subscriptions'
export const SET_COMPANY_ACTIVE_SERVICE_BY_TYPE = 'set_company_active_service_by_type'
export const SET_COMPANY_ACTIVE_PRODUCT_BUNDLES = 'set_company_active_product_bundles'

// Company Details
export const LOAD_COMPANY_DETAILS_START = 'LOAD_COMPANY_DETAILS_START'
export const LOAD_COMPANY_DETAILS_COMPLETED = 'LOAD_COMPANY_DETAILS_COMPLETED'
export const LOAD_COMPANY_DETAILS_FAILED = 'LOAD_COMPANY_DETAILS_FAILED'

// Credit Card Processing
export const SET_QUOTE_STATUS = 'set_quote_status'
export const SET_SIGNATURE_ID = 'set_signature_id'
export const SET_MERCHANT_SERVICE_ELIGIBILITY = 'set_merchant_service_eligibility'
export const SET_PROCESSING_AD_STATUS = 'set_processing_ad_status'

// Dashpanel
export const SET_UNREAD_DOCUMENTS = 'setUnreadDocuments'
export const SET_UNPAID_INVOICES = 'setUnpaidInvoices'
export const SET_ORDER_ITEMS_REQUIRING_ATTENTION_COUNT = 'setOrderItemsRequiringAttentionCount'
export const INCREMENT_ORDER_ITEMS_REQUIRING_ATTENTION_COUNT = 'incrementOrderItemsRequiringAttentionCount'
export const SET_SERVICES_REQUIRING_ATTENTION_COUNT = 'setServicesRequiringAttentionCount'
export const SET_COMPANIES_REQUIRING_CTA_IDS = 'setCompaniesRequiringCtaIds'
export const SET_PENDING_FILINGS = 'setPendingFilings'
export const SET_INCOMPLETE_STAGELINE_STEPS = 'setIncompleteStagelineSteps'
export const SET_UPCOMING_COMPLIANCE_EVENTS = 'setUpcomingComplianceEvents'
export const SET_IS_MONTANA = 'setIsMontana'
export const SET_IS_SUBSCRIPTION_ACCOUNT = 'setIsSubscriptionAccount'
export const SET_IS_ENTITY_TYPE_DOMAINS = 'setIsEntityTypeDomains'
export const SET_ACTIVE_LAW_ON_CALL_SERVICES = 'setActiveLawOnCallServices'
export const SET_ACTIVE_DOMAINS = 'setActiveDomains'
export const SET_LAW_ON_CALL_OFFERED = 'setLawOnCallOffered'
export const SET_MONITORING_OFFERED = 'setMonitoringOffered'
export const SET_PENDING_VEHICLE_ORDERS = 'setPendingVehicleOrders'

// Discounts
export const SET_DISCOUNTS = 'set_discounts'

// Documents
export const RESET_CLIENT_DOCUMENT = 'reset_client_document'
export const SET_CLIENT_DOCUMENT = 'set_client_document'
export const CACHE_CLIENT_DOCUMENT_PAGE = 'cache_client_document_page'
export const CACHE_CLIENT_DOCUMENT_PAGE_URL = 'cache_client_document_page_url'
export const CACHE_CLIENT_DOCUMENT_PAGE_ROTATION = 'cache_client_document_page_rotation'
export const SET_CLIENT_DOCUMENT_URLS = 'set_client_document_urls'

// Entity Types
export const SET_ENTITY_TYPES = 'set_entity_types'
export const SET_ENTITY_TYPE_SUFFIXES = 'set_entity_type_suffixes'

// Ephemeral Messages
export const SET_EPHEMERAL_MESSAGES = 'set_ephemeral_messages'
export const UPDATE_EPHEMERAL_MESSAGES = 'update_ephemeral_messages'

//Faq
export const SET_FAQ_INFORMATION = 'set_faq_information'

// File Drop
export const RESET_FILE_DROP = 'reset_file_drop'
export const ADD_STAGED_FILES = 'add_staged_files'
export const SET_FILES_UPLOADING = 'set_files_uploading'
export const SET_FILES_UPLOADED = 'set_files_uploaded'

// Filing Methods
export const LOAD_FM_SCHEMA_START = 'load_fm_schema_start'
export const LOAD_FM_SCHEMA_FINISHED = 'load_fm_schema_finished'

// Invitations
export const SET_RECEIVED_INVITATIONS_COUNT = 'set_received_invitations_count'
export const SET_SENT_INVITATIONS = 'set_sent_invitations'
export const SET_RECEIVED_INVITATIONS = 'set_received_invitations'

// Invoices
export const LOAD_INVOICES_START = 'load_invoices_start'
export const LOAD_INVOICES_FINISHED = 'load_invoices_finished'
export const LOAD_INVOICES_FAILED = 'load_invoices_failed'
export const SET_REFUND_TOTAL = 'set_refund_total'

// Jurisdictions
export const LOAD_JURISDICTIONS_START = 'load_jurisdictions_start'
export const LOAD_JURISDICTIONS_FINISHED = 'load_jurisdictions_finished'

// Law On Call
export const LOAD_LEGAL_SPECS_STARTED = 'load_legal_document_started'
export const LOAD_LEGAL_SPECS_FINISHED = 'load_legal_document_finished'
export const SET_LEGAL_SPECS = 'set_legal_specs'
export const LOAD_TOS_DOCS_STARTED = 'load_tos_document_started'
export const LOAD_TOS_DOCS_FINISHED = 'load_tos_document_finished'
export const SET_TOS_DOCS = 'set_tos_docs'

// Monitoring
export const SET_OFFER_MONITORING = 'set_offer_monitoring'
export const SET_MONITORING_EXCLUSIONS = 'set_monitoring_exclusions'
export const SET_MONITORING_IDENTITIES = 'set_monitoring_identities'
export const SET_MONITORING_IDENTITIES_BY_TYPE = 'set_monitoring_identities_by_type'
export const SET_MONITORING_IDENTITY_MAX = 'set_monitoring_identity_max'
export const SET_MONITORING_NOTIFICATION_SCHEDULES = 'set_monitoring_notification_schedules'

// Orders
export const LOAD_ORDERS_START = 'load_orders_start'
export const LOAD_ORDERS_FINISHED = 'load_orders_finished'
export const LOAD_ORDERS_FAILED = 'load_orders_failed'

// Order Items
export const LOAD_FILINGS_START = 'load_filings_start'
export const LOAD_COMPLIANCE_FILINGS_FINISHED = 'load_compliance_filings_finished'
export const LOAD_JURISDICTION_FILINGS_FINISHED = 'load_jurisdiction_filings_finished'
export const SET_EIN_ELIGIBILITY_STATUS = 'set_ein_eligibility_status'
export const SET_SCORP_ELIGIBILITY_STATUS = 'set_s_corp_eligibility_status'
export const SET_REQUIRES_FORMATION_FILING = 'set_requires_formation_filing'
export const SET_ORDER_ITEMS_REQUIRING_ATTENTION = 'set_order_items_requiring_attention'

// Products
export const SET_SELECTED_PRODUCT = 'set_selected_product'
export const SET_PRODUCT_BUCKETS = 'set_product_bucket'
export const SET_PRODUCT_CATEGORIES = 'set_product_category'
export const SET_OPTIONAL_PRODUCT_CATEGORIES = 'set_optional_categories'
export const SET_PRODUCTS = 'set_product'

// Request Document
export const SET_DOCUMENTS = 'set_documents'
export const SET_SELECTED_DOCUMENT_ID = 'set_selected_document_id'
export const SET_SELECTED_DOCUMENT_COMPANY_ID = 'set_selected_document_company_id'
export const SET_SHIPPING_COST = 'set_shipping_cost'
export const SET_SHIPPING_TYPE = 'set_shipping_type'
export const SET_SHIPPING_DISCOUNT = 'set_shipping_discount'
export const SET_SHIPPING_TYPE_BY_COUNTRY = 'set_shipping_type_by_country'
export const SET_SERVICE_FEE = 'set_service_fee'
export const SET_SERVICE_DISCOUNT = 'set_service_discount'
export const SET_DOCUMENT_REQUESTS_ENABLED_ON_WEBSITE = 'set_document_requests_enabled_on_website'
export const SET_SELECTED_MAILING_ADDRESS = 'set_selected_mailing_address'
export const SET_SELECTED_PAYMENT_METHOD = 'set_selected_payment_method'
export const SET_AGREEMENT_ACCEPTED = 'set_agreement_accepted'
export const SET_DOCUMENT_IMAGE_URL = 'set_document_image_url'
export const SET_USPS_VERIFIED_ADDRESS = 'set_usps_verified_address'
export const SET_COMPANY_DISCOUNTS = 'set_company_discounts'
export const SET_SERVICE_TOTAL = 'set_service_total'
export const SET_SHIPPING_TOTAL = 'set_shipping_total'
export const SET_SHIPPING_ERROR = 'set_shipping_error'


// Schema
export const SET_SCHEMA_OBJECT_TYPE = 'set_schema_object_type'
export const SET_SCHEMA_OBJECT = 'set_schema_object'
export const SET_SCHEMA = 'set_schema'
export const SET_USE_WRAPPER_VALIDATIONS = 'set_use_wrapper_validations'
export const UPDATE_WRAPPER_VALIDATION_OBSERVERS = 'update_wrapper_validation_observers'
export const RESET_WRAPPER_VALIDATION_OBSERVERS = 'reset_wrapper_validation_observers'
export const SET_SHOW_WRAPPER_VALIDATION_ERRORS = 'set_show_wrapper_validation_errors'
export const SET_SHOW_ADDRESS_PRIVACY_BORDER = 'set_show_address_privacy_border'


// Services
export const SET_SERVICES = 'set_services'
export const ADD_UPDATE_SERVICES = 'add_update_services'
export const SET_SELECTED_SERVICES = 'set_selected_services'
export const SET_SERVICE_BILLING_DETAILS = 'set_service_billing_details'
export const SET_COMPLIANCE_EVENTS = 'set_compliance_events'


// Session
export const SET_TOKEN = 'setToken'
export const SET_EXPIRATION = 'setExpiration'
export const SET_ACCOUNT_ID = 'setAccountId'
export const SET_ACCOUNT_EMAIL = 'setAccountEmail'
export const SET_WEBSITE_ID = 'setWebsiteId'
export const SET_PREVIOUS_LINK = 'setPreviousLink'
export const SET_REDIRECT_URL = 'setRedirectUrl'
export const SET_INITIALIZED = 'setInitialized'
export const SET_KEYCLOAK = 'setKeycloak'
export const SET_KEYCLOAK_AUTHENTICATED = 'setKeycloakAuthenticated'
export const SET_KEYCLOAK_CONFIG = 'setKeycloakConfig'

// Signatures
export const SET_SIGNATURES = 'set_signatures'
export const SET_SIGNATURE_TYPES = 'set_signature_types'

// Sms
export const GET_ROOMS = 'get_rooms'
export const LOAD_MESSAGES_START = 'load_messages_start'
export const LOAD_MESSAGES_FINISHED = 'load_messages_finished'
export const GET_MESSAGES = 'get_messages'
export const SET_MESSAGES_WITH_FORMAT = 'set_messages_with_format'

// export const SET_UNREAD_COUNTER = 'set_unread_counter'

// Stageline
export const SET_PERIODS = 'set_periods'
export const RESET_STAGELINE = 'reset_stageline'
export const SET_JURISDICTION = 'set_jurisdiction'
export const SET_CURRENT_SLIDE = 'set_current_slide'
export const SET_CURRENT_PERIOD_NAME = 'set_current_period_name'
export const SET_STAGELINE_LOADED = 'set_stageline_loaded'
export const SET_PERIODS_LOADED = 'set_periods_loaded'
export const SET_PERIOD_DEEP = 'set_period_deep'
export const SET_SLIDE_LOADED = 'set_slide_loaded'
export const CLEAR_PERIODS = 'clear_periods'
export const CLEAR_SLIDES = 'clear_slides'
export const SET_ACCOUNT_COMPANIES = 'set_account_companies'
export const SET_HIDE_NEXT_BUTTON = 'set_hide_next_button'
export const SET_PROCESS_FILING_OBJECTS = 'set_process_filing_objects'
export const SET_STARTING_FIELD = 'set_starting_field'
export const SET_CURRENT_FIELD = 'set_current_field'
export const SET_SLIDE_PROGRESS = 'set_slide_progress'
export const SET_OBJECT_ID = 'set_object_id'
export const SET_COMPANY_STAGE_DATA = 'set_company_stage_data'
export const SET_DECISION_TILES = 'set_decision_tiles'
export const ADD_PRODUCT_SLIDE_PRODUCT = 'add_product_slide_product'
export const SET_GHOST_MODE = 'set_ghost_mode'
export const SET_SLIDE_AGENCY_RESOURCE = 'set_slide_agency_resource'
export const SET_PRODUCT_PURCHASED = 'set_product_purchased'
export const SET_SHOW_END_OF_STAGE_SLIDE = 'set_show_end_of_stage_slide'

// StagelineSchemaForm
export const SET_RESOURCE_FIELDS = 'set_resource_fields'
export const SET_RESOURCE = 'set_resource'
export const SET_FORM_DATA = 'set_form_data'
export const SET_PROCESSED_FORM_DATA = 'set_processed_form_data'
export const SET_FORM_VALUE = 'set_form_value'
export const SUBMITTING_FORM_DATA = 'submitting_form_data'
export const SET_RA_ADDRESS_RESET = 'set_ra_address_reset'
export const RESET_STAGELINE_SCHEMA_FORM = 'reset_stageline_schema_form'
export const SET_CURRENT_PAGE = 'set_current_page'
export const SET_PDF_ADDRESS_CHECKBOX_STATE = 'set_pdf_address_checkbox_state'
export const SET_AUTOSAVE_STATUS = 'set_autosave_status'
export const SET_VERIFY_ORDER_PEOPLE = 'set_verify_order_people'
export const SET_DEBUG_DOCUMENT_VIEWER_EDIT_MODE = 'set_debug_document_viewer_edit_mode'

// Stageline Client Feedback
// Stageline Client Feedback: Common Properties
export const SET_CLIENT_FEEDBACK_OPTIONS = 'set_client_feedback_options'
export const SET_CLIENT_FEEDBACK_SOURCES = 'set_client_feedback_sources'
export const SET_CLIENT_FEEDBACK_CLIENT_DATA = 'set_client_feedback_client_data'
export const SET_ALL_CLIENT_FEEDBACK_BY_COMPANY_ID = 'set_all_client_feedback_by_company_id'
// Stageline Client Feedback: Sidebar-specific properties
export const SET_CLIENT_FEEDBACK_SHOW_SIDEBAR = 'set_client_feedback_show_sidebar'
export const SET_CLIENT_FEEDBACK_SIDEBAR_SOURCE_ID = 'set_client_feedback_sidebar_source_id'
export const SET_ALL_CLIENT_FEEDBACK_FOR_SIDEBAR = 'set_all_client_feedback_for_sidebar'
// Stageline Client Feedback: End Of Stage-specific properties
export const SET_CLIENT_FEEDBACK_END_OF_STAGE_SOURCE_ID = 'set_client_feedback_end_of_stage_source_id'
export const SET_ALL_CLIENT_FEEDBACK_FOR_END_OF_STAGE = 'set_all_client_feedback_for_end_of_stage'
export const SET_CLIENT_FEEDBACK_CHOICE = 'set_client_feedback_choice'
export const SET_CLIENT_SAVED_FEEDBACK = 'set_client_saved_feedback'
export const SET_CURRENT_CLIENT_FEEDBACK_OBJ = 'set_current_client_feedback_obj'
export const SET_PROCESSED_CLIENT_FEEDBACK = 'set_processed_client_feedback'
// END! Stageline Client Feedback

// Stageline Create Company
export const SET_PREVIOUS_SLIDES = 'set_previous_slides'
export const SET_COMPANY_JURISDICTION = 'set_company_jurisdiction'
export const SET_COMPANY_ENTITY_TYPE = 'set_company_entity_type'
export const SET_COMPANY_ENTITY_TYPE_SUFFIX = 'set_company_entity_type_suffix'
export const SET_COMPANY_NAME = 'set_company_name'
export const SET_CREATING_COMPANY = 'set_creating_company'
export const SET_CREATE_COMPANY_PAGE_LOADED = 'set_create_company_page_loaded'
export const SET_CURRENT_CREATE_COMPANY_SLIDE = 'set_current_create_company_slide'
export const SET_SIMILAR_COMPANY = 'set_similar_company'

// Stageline Company Overview
export const SET_COMPANY_OVERVIEW_DETAILS_SCHEMA = 'set_company_overview_details_schema'
export const SET_PROCESSED_COMPANY_DETAILS = 'set_processed_company_details'

// Stageline V3/Stage Manager
export const SET_STAGES = 'set_stages'
export const SET_STAGE = 'set_stage'
export const SET_STEPS = 'set_steps'
export const SET_ACCOUNTS_STAGE = 'set_accounts_stage'
export const SET_ACCOUNTS_STAGES = 'set_accounts_stages'
export const SET_SLIDE = 'set_slide'

// Subscription Bundles
export const SUBSCRIPTIONS_LOADED = 'subscriptions_loaded'
export const SUBSCRIPTION_SUMMARY_LOADED = 'subscription_summary_loaded'
export const SUBSCRIPTION_BALANCE_LOADED = 'subscription_balance_info_loaded'
export const SUBSCRIPTION_CONVERT_RESPONSE = 'subscription_convert_response'

// Vehicle Registrations
export const VEHICLES_LOADED = 'vehicles_loaded'
export const VEHICLE_MAKES = 'vehicle_makes'
export const VEHICLE_MODELS = 'chosen_vehicle_models'
export const VEHICLE_VIN_INFO = 'vehicle_vin_info'
export const VEHICLE_PRODUCTS = 'vehicle_products'
export const CURRENT_VEHICLE = 'current_vehicle'
export const VEHICLE_ORG_PLATES = 'vehicle_org_plates'
export const VEHICLE_FORM_DATA = 'get_form_data'
export const VEHICLE_REGISTRATION_ENTITY_TYPES = 'get_vehicle_registration_entity_types'
export const VEHICLE_REGISTRATION_PRODUCTS = 'get_vehicle_registration_products'
export const VEHICLE_REGISTRATION_SIMPLE_PRODUCTS = 'get_vehicle_registration_simple_products'

// Verify Order
export const SET_ORDER_REQUIRING_VERIFICATION = 'set_order_requiring_verification'
export const SET_VERIFY_ORDER_REDIRECTED = 'set_verify_order_redirected'
export const SET_VERIFY_ORDER_PROGRESS = 'set_verify_order_progress'
export const SET_VERIFY_ORDER_CURRENT_SECTION = 'set_verify_order_current_section'
export const SET_VERIFY_ORDER_COMPLETED_SECTIONS = 'set_verify_order_completed_sections'
export const SET_VERIFY_ORDER_SECTIONS = 'set_verify_order_sections'
export const SET_VERIFY_ORDER_INTERACTION = 'set_verify_order_interaction'
export const SET_ORDERS_REQUIRING_VERIFICATION_LOADED = 'set_orders_requiring_verification_loaded'
export const SET_VERIFY_ORDER_OPTIONAL_CATEGORIES = 'set_verify_order_optional_categories'
export const SET_UPSELL_OPTIONS = 'set_upsell_options'
export const SET_DEPENDENT_FILINGS_FILING_METHOD = 'set_dependent_filings_filing_method'
export const SET_DOMAIN_SEARCH = 'set_domain_search'
export const SET_DOMAIN_SEARCH_RESULTS = 'set_domain_search_results'
export const SET_NAMESERVERS = 'set_nameservers'

// Virtual Phones
export const LOAD_VIRTUAL_PHONES_START = 'load_virtual_phones_start'
export const LOAD_VIRTUAL_PHONES_FINISHED = 'load_virtual_phones_finished'
export const LOAD_SUSPENDED_PHONES_START = 'load_suspended_phones_start'
export const LOAD_SUSPENDED_PHONES_FINISHED = 'load_suspended_phones_finished'
export const LOAD_VOICEMAILS_START = 'load_voicemails_start'
export const LOAD_VOICEMAILS_FINISHED = 'load_voicemails_finished'
export const SET_VIRTUAL_PHONES_PENDING_REMOVAL = 'set_virtual_phones_pending_removal'
export const SET_SELECTED_PHONE_ID = 'set_selected_phone_id'
export const SET_SELECTED_VIRTUAL_PHONE = 'set_selected_phone'
export const SET_SELECTED_VOICEMAIL_ID = 'set_selected_voicemail_id'
export const SET_VOICEMAIL_DURATION = 'set_voicemail_duration'
export const SET_SELECTED_VOICEMAIL_SIGNED_URL = 'set_selected_voicemail_signed_url'
export const SET_SELECTED_AREA_CODE = 'set_selected_area_code'
export const SET_VOICEMAIL_LIFESPAN_IN_DAYS = 'set_voicemail_lifespan_in_days'
export const SET_VIRTUAL_PHONES_TO_CONFIGURE = 'set_virtual_phones_to_configure'
export const SET_VIRTUAL_PHONE_CNAM_PARAMS = 'SET_VIRTUAL_PHONE_CNAM_PARAMS'
export const SET_RING_COUNT = 'set_ring_count'
export const SET_GREETING_TYPE = 'set_greeting_type'
export const SET_CUSTOM_VOICEMAIL_URL = 'set_custom_voicemail_url'
export const SET_TRIAL_ELIGIBILITY='set_trial_eligibility'
export const SET_MONITORING_TRIAL_ELIGIBILITY='set_monitoring_trial_eligibility'
export const LOAD_AVAILABLE_NUMBERS_FINISHED = 'load_available_numbers_finished'
export const SET_SELECTED_AVAILABLE_NUMBER = 'set_selected_available_number'
export const SET_PHONE_NAME = 'set_phone_name'
export const SET_FORWARD_TYPE = 'set_forward_type'
export const SET_FORWARD_TO_NUMBER = 'set_forward_to_number'
export const SET_FORWARD_AS = 'set_forward_as'
export const RESET_MODAL_FIELDS = 'reset_modal_fields'
export const RESET_PHONE_FIELDS = 'reset_phone_fields'
export const SET_VIRTUAL_PHONE_ID = 'set_virtual_phone_id'
export const SET_MOBILE_ACCESS_FINISHED = 'set_mobile_access_finished'
export const MODAL_SUBMISSION_FINISHED = 'modal_submission_finished'
export const MODAL_SUBMISSION_IN_PROGRESS = 'modal_submission_in_progress'
export const SET_PHONE_NOTIFICATION_COUNT = 'set_phone_notification_count'
export const SET_BLOCKED_NUMBERS = 'set_blocked_numbers'
export const SET_CALL_LOGS = 'set_call_logs'
export const SET_SUSPENDED_PHONES = 'set_suspended_phones'
export const SET_VIRTUAL_PHONE_TO_CONFIGURE = 'set_virtual_phone_to_configure'
export const SET_VIRTUAL_PHONE_CONFIGURATION_IN_PROGRESS = 'set_virtual_phone_configuration_in_progress'

// Monitoring
export const SET_MONITORING_SERVICE='set_monitoring_service'
export const SET_MONITORING_SERVICE_ACTIVE='set_trial_eligibility'
export const SET_MONITORING_EMAIL_ADDRESS='set_monitoring_email_address'
export const SET_MONITORING_CANDIDATES='set_monitoring_candidates'
export const SET_MONITORING_REPORTS='set_monitoring_reports'

// Vouchers
export const SET_VOUCHERS = 'set_vouchers'
export const SET_ACCOUNT_DOMAIN_VOUCHERS = 'set_account_domain_vouchers'

// Digital Wallet Survey
export const SET_DIGITAL_WALLET_SURVEY_VOTED = 'set_digital_wallet_survey_voted'
export const SET_DIGITAL_WALLET_SURVEY_KEEP_OPEN = 'set_digital_wallet_survey_keep_open'

// Misc
export const RESET_STATE = 'reset_state'

// Accounts Receivable payment methods
export const SET_AR_AUTOMATED_CLEARING_HOUSES = 'set_accounts_receivable_automated_clearing_houses'
export const SET_AR_CARDS = 'set_accounts_receivable_cards'
export const SET_AR_CUSTOMER_TOKEN = 'set_accounts_receivable_customer_token'
export const SET_AR_CUSTOMER_TOKEN_EXPIRATION = 'set_accounts_receivable_customer_token_expiration'
export const SET_AR_DIGITAL_WALLETS = 'set_accounts_receivable_digital_wallets'
export const SET_AR_LOADING_PAYMENT_METHODS = 'set_accounts_receivable_loading_payment_methods'
export const SET_AR_INFO_AGREEMENT = 'set_accounts_receivable_retain_info_agreement'
export const SET_AR_SELECTED_PAYABLE_TYPE = 'set_accounts_receivable_selected_payable_type'
export const SET_AR_CURRENT_TOTAL = 'set_accounts_receivable_current_total'
export const SET_AR_EXCEEDS_ACH_TRANSACTION_LIMIT = 'set_accounts_receivable_exceeds_ach_transaction_limit'
export const SET_AR_EXPIRED_CARD_CONVERSION = 'set_accounts_receivable_expired_card_conversion'
export const SET_AR_IS_GUEST_PAY = 'set_accounts_receivable_is_guest_pay'

// Feed Notifications
export const SET_FEED_NOTIFICATIONS = 'set_feed_notifications'
export const SET_FEED_EXPIRATION = 'set_feed_expiration'

// Action Cable
export const SET_ACTION_CABLE = 'set_action_cable'
export const SET_REQUESTS = 'set_requests'
