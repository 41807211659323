import mediaTypes from '@/lib/mediaTypes'
import * as t from '@/store/mutations'
const { jsonApi } = mediaTypes

const initialState = () => ({
  stages: [],
  stage: {},
  steps: [],
  accountsStage: {},
  accountsStages: [],
  slide: {},
})

const STATE = initialState()

const GETTERS = {
  stages: state => state.stages,
  stage: state => state.stage,
  steps: state => state.steps,
  accountsStage: state => state.accountsStage,
  accountsStages: state => state.accountsStages,
  slide: state => state.slide,
  isFirstSlide: state => state.slide.position === 0 && state.steps.find(s => s.id === state.slide.step.id)?.position === 0,
  isLastSlide: state => state.slide.is_last,
  requestConfig: (_state, _getters, _rootState, rootGetters) => ({
    baseURL: process.env.VUE_APP_STAGE_MANAGER_URL,
    headers: {
      Authorization: `Bearer ${rootGetters['session/getToken']}`,
    },
  }),
  // Only allow admins to pass these values.
  defaultParams: (_state, _getters, _rootState, rootGetters) => ({
    ...(rootGetters['session/isAdminImpersonatingClient'] && {
      account_id: rootGetters['account/actualAccountID'],
      reseller_id: rootGetters['account/websiteId'],
      impersonating: true,
    }),
  }),
}

// TODO: Should be able to dynamically populate state based on JSON:API responses.
const ACTIONS = {
  // GET requests
  async getAccountsStages({ commit, getters }, { clear = true, company_id = null, title = null, ...jsonApiConfig }) {
    const response = await jsonApi.get('/api/v1/stage_manager/accounts_stages', getters.requestConfig, {
      ...jsonApiConfig,
      ...(company_id != null && { company_id }),
      ...(title != null && { title }),
      ...getters.defaultParams,
    })

    if (jsonApiConfig.include?.includes('stage')) {
      commit(t.SET_STAGES, response.map(item => item.stage))
    }
    const accountsStages = clear ? response : [...getters.accountsStages, ...response]
    commit(t.SET_ACCOUNTS_STAGES, accountsStages)
  },
  async getAccountsStage({ commit, getters }, { id = null, data = {}, ...jsonApiConfig }) {
    const response = await jsonApi.get(`/api/v1/stage_manager/accounts_stages/${id}`, getters.requestConfig, {
      ...jsonApiConfig,
      ...getters.defaultParams,
      data,
    })
    commit(t.SET_ACCOUNTS_STAGE, response)

    if (jsonApiConfig.include?.includes('stage')) {
      commit(t.SET_STAGE, response.stage)
    }

    if (jsonApiConfig.include?.includes('stage.steps')) {
      commit(t.SET_STEPS, response.stage.steps)
    }

    if (jsonApiConfig.include?.includes('current_slide')) {
      commit(t.SET_SLIDE, response.current_slide)
    }
  },

  // PATCH requests
  async updateAccountsStage({ commit, getters }, { id, data = {}, ...jsonApiConfig }) {
    const response = await jsonApi.patch(`/api/v1/stage_manager/accounts_stages/${id}`, getters.requestConfig, {
      data: jsonApi.serialize({ id, type: 'accounts_stage', data }),
      ...jsonApiConfig,
      ...getters.defaultParams,
    })
    commit(t.SET_ACCOUNTS_STAGE, response)

    if (jsonApiConfig.include?.includes('stage')) {
      commit(t.SET_STAGE, response.stage)
    }

    if (jsonApiConfig.include?.includes('stage.steps')) {
      commit(t.SET_STEPS, response.stage.steps)
    }

    if (jsonApiConfig.include?.includes('current_slide')) {
      commit(t.SET_SLIDE, response.current_slide)
    }
  },
}

const MUTATIONS = {
  [t.SET_STAGES](state, stages) {
    state.stages = stages
  },
  [t.SET_STAGE](state, stage) {
    state.stage = stage
  },
  [t.SET_STEPS](state, steps) {
    state.steps = steps
  },
  [t.SET_ACCOUNTS_STAGE](state, accountsStage) {
    state.accountsStage = accountsStage
  },
  [t.SET_ACCOUNTS_STAGES](state, accountsStages) {
    state.accountsStages = accountsStages
  },
  [t.SET_SLIDE](state, slide) {
    state.slide = slide
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
